import { useState } from 'react';
import logo from '../assets/logos/vita_logo_main.png';
import Navlist from './Subcomponents/NavlistLight';
import { List } from 'react-bootstrap-icons';

const Navbar = () => {

    const [sidebar, setSidebar] = useState(false);
    const showSideBar = () => setSidebar(!sidebar);

    return (
        <div className="flex items-center justify-between flex-row shadow-lg fixed top-0 h-16 md:h-18 w-screen bg-white px-5 py-10 z-50">
            <a href="/"><img className="h-16 md:h-16" src={logo} /></a>
            <Navlist />
            <i onClick={ showSideBar } className="text-vitagrey lg:hidden">{<List size="30" />}</i>
            <DrawerMenu sidebarstate={ sidebar } />
        </div>
    );
}

const DrawerMenu = (sidebarstate) => {
    
    return (
        <div className={ sidebarstate.sidebarstate ? "absolute top-20 right-0 items-center justify-middle w-1/2 h-screen" : "hidden" }>
            <div className="flex flex-col bg-white text-left rounded-bl-lg shadow-xl">
                <a className="ml-5 my-4 text-sm font-extrabold text-vitagrey hover:text-vitagold tracking-wide transition-all duration-100 font-vitaBody" href="/">Home</a>
                <a className="ml-5 my-4 text-sm font-extrabold text-vitagrey hover:text-vitagold tracking-wide transition-all duration-100 font-vitaBody" href="/treatment">Treatment Guide</a>
                <a className="ml-5 my-4 text-sm font-extrabold text-vitagrey hover:text-vitagold tracking-wide transition-all duration-100 font-vitaBody" href="/profile">Dr Henning</a>
                <a className="ml-5 my-4 text-sm font-extrabold text-vitagrey hover:text-vitagold tracking-wide transition-all duration-100 font-vitaBody" href="/contact">Contact</a>
            </div>
        </div>
    );
}

export default Navbar; 