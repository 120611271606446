import React from "react";

const Navlist = () => {
    return (
        <div className="hidden md:flex items-center justify-middle flex-row m-4">
            <Navitem linkname={ "Home" } link={"/"} />
            {/* <Navitem linkname={ "About" } /> */}
            <Navitem linkname={ "Treatment Guide"} link={"/treatment"}/>
            <Navitem linkname={ "Dr Henning"} link={"/profile"}/>
            {/* <Navitem linkname={ "Gallery" }/> */}
            <Navitem linkname={ "Contact" } link={"/contact"}/>
        </div>
    );
}

const Navitem = ({ linkname, link }) => {
    return (
        <a className="ml-10 text-white text-sm hover:text-vitagold tracking-wide transition-all duration-100 font-vitaBody" href={link}>{linkname}</a>
    );
}

export default Navlist;