import heroimage from '../assets/Core Images/heroHeader_one.jpg'
import logo from '../assets/logos/vita_logo_white.png';
import syringeImage from '../assets/icons/syringe.jpg'
import stitchImage from '../assets/icons/stitch_image.svg'
import wrinkleImage from '../assets/icons/wave_image.svg'
import botoxLogo from '../assets/logos/botox_logo.png';
import juvedermLogo from '../assets/logos/juvederm_logo.png';
import restylaneLogo from '../assets/logos/restylane_logo.png';
import tasosolLogo from '../assets/logos/tasosol_logo.png';
import aespioLogo from '../assets/logos/aespio_logo.png';
import consultImg from '../assets/Core Images/doc_with_patient.jpg';


const Home = () => {
    return (
        <div className="flex flex-col justify-center bg-white max-w-full overflow-hidden">
            <Hero />
            <About />
            <Services />
            <ServiceDetailed serviceName={"Facial Aesthetics"} />
            <BannerImage targetimage={consultImg} />
            <Products />
            <ContactNav />
        </div>
    );
}

const Hero = () => {
    return (
        <div className="flex flex-col items-center place-content-center h-screen overflow-hidden justify-centre mt-16 md:mt-18 max-w-full">
            <img className="h-full object-cover lg:h-auto lg:w-full opacity-75" src={heroimage} />
            <div className="absolute flex flex-col h-screen w-full items-middle ">
                <div className="flex flex-col h-screen w-full justify-center items-center p-1 md:p-10 bg-vitagrey/30">
                    <img className="h-20 md:h-28 rounded-full" src={logo} />
                    <h1 className="text-white text-center text-5xl md:text-9xl tracking-wide font-vitaHeadings">Vita Aesthetics</h1>
                    <p className="mt-2 font-vitaBody font-extrabold md:tracking-wider text-white text-sm md:text-lg uppercase text-center justify-center">Minimally invasive facial aesthetics & more</p>
                    <p className="mt-1 font-vitaBody font-extrabold md:tracking-wider text-white text-base md:text-lg uppercase">•</p>
                    <p className="mt-1 font-vitaBody font-extrabold md:tracking-wider text-white text-sm md:text-lg uppercase">Cape Town</p>
                </div>

            </div>
        </div>
    );
}


const About = () => {
    return (
        <div className="flex flex-row my-10 md:my-28 justify-center">
            <div className="flex flex-col justify-start items-center mx-6 m-4 md:w-2/3">
                <h2 className="text-vitagrey font-extralight text-3xl font-vitaHeadings tracking-wide mb-10 text-center" >Welcome to Vita Aesthetics</h2>
                <p className="text-vitagrey font-extrabold md:font-normal font-vitaBody text-lg tracking-wide text-center md:text-center mb-4 w-full">{aboutparagraph_1}</p>
                <p className="text-vitagrey font-extrabold md:font-normal font-vitaBody text-lg tracking-wide text-center md:text-center mb-4 w-full">{aboutparagraph_2}</p>
                <p className="text-vitagrey font-extrabold md:font-normal font-vitaBody text-lg tracking-wide text-center md:text-center mb-4 w-full">{aboutparagraph_3}</p>
                {/* <p className="text-vitagrey font-vitaBody text-md tracking-wide text-center md:text-center mb-4 w-full">{aboutparagraph_4}</p>  */}
            </div>
        </div>
    );
}

const aboutparagraph_1 = "At Vita we believe the face is a window into your own life, so let it reveal a life of beauty, confidence, and meaning.";
const aboutparagraph_2 = "The word 'Vita', derived from Latin, means 'life', reflecting the essence of life and the impact aesthetic medicine can have on contributing to a happier and more fulfilling life.";
const aboutparagraph_3 = "Vita Aesthetics is an aesthetic medicine practice founded by Dr Michael Henning.";
const aboutparagraph_4 = "Please note that Vita is not currently operational and will open its doors in the year 2024.";


const Services = () => {
    return (
        <div className="flex flex-col bg-vitagrey items-center p-10 mb:10 md:mb-16 py-10 md:py-16 border-t-2 border-b-2 border-vitagold">
            <h2 className="text-white font-vitaHeadings text-3xl text-center tracking-wide mb-10 md:mb-6" >Our Services</h2>
            <div className="flex flex-col md:flex-row">
                <Servicecard service={"Facial Aesthetics"} serviceDescription={serviceDescription_1} />
                <Servicecard service={"Sclerotherapy"} serviceDescription={serviceDescription_2} />
            </div>
        </div>
    );
}

const Servicecard = ({ service, serviceDescription }) => {
    return (
        <div className="flex flex-col max-w-md border-2 border-white mx-2 my-6 md:m-16 p-4 items-center">
            <h2 className="text-xl text-white font-vitaHeadings tracking-wide mb-4">{service}</h2>
            <p className="text-white font-semibold md:font-normal font-vitaBody text-md tracking-wide text-center">{serviceDescription}</p>
        </div>
    );
}

const serviceDescription_1 = "We offer a variety of facial aesthetic treatments, which can be used individually or in combination to subtly enhance and refine facial features while reducing the signs of aging.";
const serviceDescription_2 = "We specialize in the treatment of telangiectasia (spider veins) and varicose veins.";

const ServiceDetailed = ({ serviceName }) => {
    return (
        <div className="flex flex-col justify-center items-center p-10 mb-2 md:mb-12">
            <h2 className="text-vitagrey text-3xl font-vitaHeadings tracking-wide mb-6" >{serviceName}</h2>
            <div className="flex flex-col  md:flex-row">
                <Treatment name={"Toxin Injections"} description={treatment_one} icon={syringeImage} />
                <Treatment name={"Dermal Fillers"} description={treatment_two} icon={wrinkleImage} />
                <Treatment name={"Thread Lifting"} description={treatment_three} icon={stitchImage} />
            </div>
        </div>
    );
}

const Treatment = ({ name, description, icon }) => {
    return (
        <div className="flex flex-col max-w-md m-2 md:m-12 p-4 items-center">
            <img className="h-12 my-2 md:my-4" src={icon} />
            <h2 className="text-lg text-vitagrey font-vitaHeadings tracking-wide mb-4">{name}</h2>
            <p className="text-vitagrey font-semibold md:font-normal font-vitaBody text-md tracking-wide text-center">{description}</p>
        </div>
    );
}

const BannerImage = ({ targetimage }) => {
    return (
        // <div className="flex flex-col items-end space-y-0 mt-2 w-full md:max-h-[800px] md:min-h-[800px] overflow-hidden">
        <div className="flex flex-col place-content-center space-y-0 items-end mt-26 md:mt-2 md:mb-0 h-[58vh] md:h-auto  w-full md:max-h-[1000px] overflow-hidden">
            <img className="w-full scale-200 md:scale-100 object-cover " src={targetimage} />
        </div>
    );
}

const treatment_one = "For wrinkle treatment and prevention, as well as relief from jaw clenching and excessive sweating.";
const treatment_two = "Using fillers to address deep wrinkles, hydrate skin, restore volume, and reshape features (e.g. lips, chin, and cheeks).";
const treatment_three = "Using dissolvable threads to enhance skin quality, volume, and tightness, while lifting sagging skin.";

const Products = () => {
    return (
        <div className="flex flex-col flex-wrap space-y-0 bg-slate-300 items-center px-10 md:pb-16 py-10 md:py-16 border-t-2 border-b-2 border-vitagold">
            <h2 className="text-white text-3xl text-center font-vitaHeadings tracking-wide mb-12" >The Products We Use</h2>
            <div className="flex flex-row flex-wrap justify-center">
                <Product productIcon={botoxLogo} />
                <Product productIcon={juvedermLogo} />
                <Product productIcon={restylaneLogo} />
                <Product productIcon={tasosolLogo} />
                <Product productIcon={aespioLogo} />
            </div>
        </div>
    );
}

const Product = ({ productIcon }) => {
    return (
        <img className="max-h-16 md:max-h-20 my-2 md:my-4 mx-2 md:mx-16 p-2" src={productIcon} />
    );
}

const ContactNav = () => {
    return (
        <div className="flex items-center justify-center my-32 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <h2 className="text-vitagrey font-vitaHeadings text-3xl text-center tracking-wide mb-6" >Want to find out more?</h2>
                <a
                    href='/contact'
                    className="font-vitaBody group relative w-full flex justify-center py-2 px-4 border border-transparent opacity-95 text-sm font-medium text-white bg-vitagrey hover:opacity-50 focus:outline-none focus:ring-2 focus:ring-offset-2 "
                >
                    Contact Us
                </a>
            </div>
        </div>
    );
};

export default Home;