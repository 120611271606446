import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';


import Navbar from './Components/NavbarLight';
import Home from './Components/Home';
import Profile from './Components/Profile';
import Treatment from './Components/Treatment';
import Footer from './Components/Footer';
import Contact from './Components/Contact';
import Floating from './Components/Floating';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Navbar /> 
    <Routes>
      <Route path='/' element={<Home /> } />
      <Route path='/profile' element={<Profile /> } />
      <Route path='/treatment' element={<Treatment /> } />
      <Route path='/contact' element={<Contact /> } />
    </Routes>
    <Floating />
    <Footer />
    </BrowserRouter> 
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
