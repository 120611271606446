// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyDTt3twLaAhAcvTDB1tJVOnlgKoKgq1UwA",
  authDomain: "vitaaesthetics-cfa49.firebaseapp.com",
  projectId: "vitaaesthetics-cfa49",
  storageBucket: "vitaaesthetics-cfa49.appspot.com",
  messagingSenderId: "665403112762",
  appId: "1:665403112762:web:949cc771415f83240f2403",
  measurementId: "G-6G54VMV792"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();

export { db, collection, addDoc };