import profileImage from '../assets/Core Images/dr_henning_portrait.JPG'; 

const Profile = () => {
    return (
        <div className="flex flex-col bg-white">
            <div className="flex flex-col md:flex-row mx-2 md:mx-32 my-16 md:my-32">
                <div className="mx-2 md:mx-10 p-2 md:p-6">
                    <img className="md:float-left mt-8 mb-8 md:mb-2 md:mt-0 md:mr-8 max-w-full md:max-w-2xl" src={profileImage} />
                    <h2 className="text-vitagrey text-3xl font-vitaHeadings tracking-wide mb-6">About Dr Henning</h2>
                    <p className="text-vitagrey font-extrabold md:font-normal font-vitaBody text-sm tracking-wide text-justify leading-relaxed  mb-6">
                        { about_text_1 }
                    </p>
                    <p className="text-vitagrey font-extrabold md:font-normal font-vitaBody text-sm tracking-wide text-justify leading-relaxed  mb-6">
                        { about_text_2 }
                    </p>
                    <p className="text-vitagrey font-extrabold md:font-normal font-vitaBody text-sm tracking-wide text-justify leading-relaxed  mb-6">
                        { about_text_3 }
                    </p>
                    <p className="text-vitagrey font-extrabold md:font-normal font-vitaBody text-sm tracking-wide text-justify leading-relaxed  mb-6">
                        { about_text_4 }
                    </p>
                </div>
            </div>
        </div>
    );
}

const about_text_1 = "Dr Michael Henning is the founder of Vita Aesthetics in Cape Town. He is a qualified medical doctor with a special interest in aesthetic medicine, primarily focussing on facial aesthetics. After graduating from the University of Cape Town (UCT) Medical School, Dr Henning completed two years of medical internship at Mitchells Plain District Hospital, and a year of community service as an Orthopaedic Medical Officer at 2 Military Hospital in Wynberg, Cape Town.";
const about_text_2 = "He has received comprehensive training in various aesthetic procedures, including neurotoxin injections (Botox and Dysport), dermal fillers, cannula filling, PDO thread lifting, and sclerotherapy. Additionally, he is actively enhancing his expertise by collaborating with advanced aesthetic practitioners, participating in accredited aesthetic courses, and pursuing an Advanced Diploma in Aesthetic Medicine, which is widely regarded as one of the most comprehensive aesthetic medicine qualifications available in South Africa.";
const about_text_3 = "Dr Henning has an unwavering passion for aesthetic medicine and takes pride in continually advancing his knowledge and expertise in the field. Embracing the philosophy that 'less is often more,' Dr Henning strives to tailor his approach to your unique needs, leveraging his specialized knowledge and meticulous attention to detail to achieve natural and sophisticated results. He firmly believes that assisting you in looking your best can contribute significantly to your overall confidence and self-esteem, bringing you closer to realizing your full potential. His background in general medicine, coupled with his expertise in aesthetic medicine, enables him to deliver aesthetic treatments with a biopsychosocial perspective, taking into account the various factors that influence health decisions, self-perception, and, ultimately, one’s happiness in life.";
const about_text_4 = "Outside of his commitments to medical practice in the field of aesthetics, Dr Henning dedicates much of his free time to maintaining a healthy lifestyle, socializing with friends and family, and enjoying the natural beauty that Cape Town has to offer.";


export default Profile;