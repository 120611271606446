import React, { useState } from 'react';
import heroImage from '../assets/Core Images/hero_girl_4.jpg';
import bannerImage_2 from '../assets/Core Images/hero_girl_2.jpg';
import bannerImage_1 from '../assets/Core Images/hero_girl_3.jpg';
import bannerImage_3 from '../assets/Core Images/hero_girl_5.jpg';
import bannerImage_4 from '../assets/Core Images/hero_girl_6.jpg';
import { PlusLg, DashLg } from 'react-bootstrap-icons';

const Treatment = () => {
    return (
        <div className="flex flex-col items-center bg-white w-screen">
            <HeroBannerImage targetimage={heroImage} />
            <div className="flex flex-col items-center mt-2 md:w-4/5 p-6 md:p-10">
                <TreatmentType name={treatment_name_1} description={treatment_description_1} content={sub_desciption_map_1} />
            </div>
            <BannerImage targetimage={bannerImage_1} />
            <div className="flex flex-col items-center mt-2 md:w-4/5 p-6 md:p-10">
                <TreatmentType name={treatment_name_2} description={treatment_description_2} content={sub_desciption_map_2} />
            </div>
            <BannerImage targetimage={bannerImage_2} />
            <div className="flex flex-col items-center mt-2 md:w-4/5 p-6 md:p-10">
                <TreatmentType name={treatment_name_3} description={treatment_description_3} content={sub_desciption_map_3} />
            </div>
            <BannerImage targetimage={bannerImage_3} />
            <div className="flex flex-col items-center mt-2 md:w-4/5 p-6 md:p-10">
                <TreatmentType name={treatment_name_5} description={treatment_description_5} content={sub_desciption_map_5} />
            </div>
            <BannerImage targetimage={bannerImage_4} />
            <div className="flex flex-col items-center mt-2 md:w-4/5 p-6 md:p-10 mb-10 md:mb-20">
                <TreatmentType name={treatment_name_4} description={treatment_description_4} content={sub_desciption_map_4} />
            </div>
        </div>
    );
}


const HeroBannerImage = ({ targetimage }) => {
    return (
        <div className="flex flex-col content-center items-center mt-16 md:mt-16 md:h-[400px] overflow-hidden">
            <img className="w-screen object-cover md:my-[-300px]" src={targetimage} />
            <h1 className="absolute text-center text-white text-5xl md:text-8xl tracking-wide font-vitaHeadings my-24 md:my-[175px] ">Treatment Guide</h1>
        </div>
    );
}

const BannerImage = ({ targetimage }) => {
    return (
        <div className="flex flex-col items-end mt-2 md:mt-10 h-[250px] md:h-[600px] overflow-hidden">
            <img className="w-screen object-cover my-[-10px] md:my-[-100px]" src={targetimage} />
        </div>
    );
}

const TreatmentType = ({ name, description, content }) => {
    let subdescription_0 = content[0];
    let subdescription_1 = content[1];
    let subdescription_2 = content[2];
    let subdescription_3 = content[3];
    return (
        <div className="flex flex-col justify-center mb-2 mt-2 md:mt-10 md:mb-2">
            <h2 className="text-xl text-vitagrey font-vitaHeadings tracking-wide mb-4">{name}</h2>
            <p className="text-vitagrey font-semibold md:font-normal text-justify font-vitaBody text-sm tracking-wide">{description}</p>
            <TreatmentDropdown heading={subheading_1} content={<TreatmentDropdownDescription subdescription={subdescription_0} />} />
            <TreatmentDropdown heading={subheading_2} content={<TreatmentDropdownDescription subdescription={subdescription_1} />} />
            <TreatmentDropdown heading={subheading_3} content={<TreatmentDropdownDescription subdescription={subdescription_2} />} />
            <TreatmentDropdown heading={subheading_4} content={<TreatmentDropdownDescription subdescription={subdescription_3} />} />
        </div>
    );
}

const TreatmentDropdown = ({ heading, content }) => {

    const [clicked, setClicked] = useState(false);
    const changeIcon = () => setClicked(!clicked);

    return (
        <div className="flex flex-col justify-center mt-10">
            <details className="flex flex-row" closed>
                <summary className="select-none">
                    <div className="flex flex-row" onClick={changeIcon}>
                        <i className={!clicked ? "text-vitagrey items-center p-1" : "hidden"}>{< PlusLg size="20" />}</i>
                        <i className={clicked ? "text-vitagrey items-center p-1" : "hidden"}>{< DashLg size="20" />}</i>
                        <h2 className="text-lg text-vitagrey font-vitaHeadings tracking-wide mb-2">{heading}</h2>
                    </div>
                </summary>
                <div className="text-vitagrey font-semibold md:font-normal font-vitaBody text-sm tracking-wide text-left">
                    {content}
                </div>
            </details>
        </div>
    );
}

const TreatmentDropdownDescription = ({ subdescription }) => {
    return (
        <ul className="list-disc ml-10">
            {subdescription.map((item) => {
                let keyNo = subdescription.indexOf(item);
                return (<li key={keyNo} className="my-2 ml-2"> {item} </li>);
            })}
        </ul>
    );
}


// vars - This can be pulled from Firebase at a latter stage

const subheading_1 = "How does it work?";
const subheading_2 = "Why is this treatment for me?";
const subheading_3 = "Before my treatment";
const subheading_4 = "After my treatment";

// treatment 1
const treatment_name_1 = "Neurotoxin Injections (Botulinum Toxin)";
const treatment_description_1 = "Neurotoxin injections, commonly referred to as 'botox,' typically involve the injection of a neurotoxin (botulinum toxin) into specific facial sites. This procedure effectively relaxes certain muscles, resulting in the improvement of wrinkles and the prevention of new ones. Common treatment areas include the forehead, frown lines, and crow's feet (around the eyes). Neurotoxin injections can also address various other conditions, such as jaw clenching or teeth grinding (bruxism) and excessive underarm sweating (axillary hyperhidrosis). This method of treatment is quick and exceptionally safe, making it one of the most sought-after procedures in the field of facial aesthetics";

const sub_desciption_1_1 = [
    "The neurotoxin used is called Botulinum toxin, an injectable neuromodulator derived from the bacteria Clostridium botulinum. When injected into or around a muscle, this toxin inhibits neurotransmission between the nerve endings and the muscle, resulting in temporary muscle weakness or paralysis. With regular treatment, wrinkles caused by these muscles gradually diminish, and ongoing use of the toxin can help prevent the development of new wrinkles. This same mechanism of action applies to the treatment of bruxism and hyperhidrosis. Current brand names of botulinum toxin used in South Africa include Botox and Dysport."
];
const sub_desciption_1_2 = [
    "Wrinkles, also known as rhytids, are a natural occurrence resulting from the ageing process and/or active muscle contractions during facial expressions. They play an essential role in conveying a range of emotions, both positive and negative. Excessive or deep wrinkles, however, can become undesirable. Botulinum toxin treatment effectively reduces or softens wrinkles while also preventing the formation of new ones.",
    "Botulinum toxin is also used for the treatment of bruxism and axillary hyperhidrosis, both of which are services offered at Vita Aesthetics. For bruxism, the treatment involves several small injections directly into the masseter muscle of the jaw. In the case of axillary hyperhidrosis, multiple small injections are administered under the armpits, requiring a larger dosage of botulinum toxin."
];
const sub_desciption_1_3 = [
    "If you exercise daily, we recommend doing so before your appointment.",
    "Please refrain from consuming alcohol 24 hours prior to your treatment",
    "It's important to note that blood-thinning medications can increase the risk of bleeding and bruising. Therefore, we advise avoiding all over-the-counter blood-thinning medications, such as anti-inflammatories or aspirin, for one week before your treatment unless they are prescribed by your doctor as part of your regular medication.",
    "Kindly come to your appointment without wearing makeup.",
];
const sub_desciption_1_4 = [
    "The results of botulinum toxin (Botox/Dysport) injections may take anywhere from 3 to 14 days to reach their full effect and typically last between 3 to 4 months, depending on the amount of toxin used and the injection site.",
    "It's common to experience some tenderness, redness, swelling, and possibly mild bruising immediately after the treatment. These side effects usually resolve quickly.",
    "Stay upright for 4 hours after your treatment.",
    "Please remain upright for at least 4 hours after your treatment.",
    "For the first 24 hours following your treatment, avoid touching, rubbing, or applying pressure to the injection sites. This helps prevent the spread of the toxin away from the intended area.",
    "You can apply ice packs to any swollen areas for up to 3 days following the treatment.",
    "If you have any concerns or questions, please don't hesitate to contact us. We are always available to assist you!"
];

const sub_desciption_map_1 = [
    sub_desciption_1_1,
    sub_desciption_1_2,
    sub_desciption_1_3,
    sub_desciption_1_4
];

// treatment 2
const treatment_name_2 = "Dermal Fillers";
const treatment_description_2 = "Dermal fillers offer an effective and reversible solution for treating wrinkles, restoring lost volume, and enhancing facial features like the lips, chin, and cheeks. These fillers contain hyaluronic acid (HA), a substance known for its remarkable filling and skin-hydrating properties, resulting in a natural look and feel. The procedure involves injecting the filler beneath the skin using a small needle or a plastic cannula. Various types of fillers are available, and the duration of results can vary, typically lasting between 9 to 12 months or even longer, depending on the specific filler used.";

const sub_desciption_2_1 = [
    "HA is naturally present in our bodies and plays a crucial role in the skin's extracellular matrix, particularly in the dermal skin layer. Its primary function is to maintain the structure, function, and moisture of our skin, thanks to its remarkable water-binding capacity. Dermal fillers are predominantly composed of HA, which undergoes chemical modification through cross-linking. This cross-linking prevents rapid breakdown by the body, a process that typically occurs with naturally occurring HA. When injected into or under the skin, these fillers, containing exogenous HA, effectively restore lost volume, lift, and hydrate the skin. Dermal fillers vary in HA concentration and gel properties, influencing each filler's clinical behaviour. It's essential to note that dermal fillers offer temporary results, gradually breaking down in the body over time. If needed, filler can be dissolved using hyaluronidase, a substance that can correct misplaced filler or address undesirable results."
];

const sub_desciption_2_2 = [
    "Dermal fillers primarily serve two purposes: volumization and augmentation.",
    "Facial ageing is a natural process that involves changes in the skin and underlying tissues, such as the loss of tissue volume in facial fat and structural support from bone. These changes can lead to wrinkles, skin sagging, and a loss of facial volume.",
    "Volumization addresses the effects of facial ageing by injecting dermal fillers beneath the skin to replace lost tissue volume, soften deep wrinkles, and restore a more youthful appearance. Common treatment areas include the upper face (forehead and brow wrinkles), midface (around the eyes and cheeks), and lower face (nasolabial folds and perioral wrinkles).",
    "Dermal fillers can also be used for facial augmentation, reshaping and enhancing specific areas like the lips, chin, and cheeks. Dr Henning is particularly passionate about using dermal fillers for lip shaping and sculpting."
];

const sub_desciption_2_3 = [
    "If you exercise daily, we recommend doing so before your appointment.",
    "Please refrain from consuming alcohol within 24 hours before your treatment.",
    "It's important to note that blood-thinning medications can increase the risk of bleeding and bruising. Therefore, we advise avoiding all over-the-counter blood-thinning medications, such as anti-inflammatories or aspirin, for one week prior to your treatment unless they are part of your regular, chronic prescription from your doctor.",
    "Kindly come to your appointment without wearing lipstick or makeup."
];

const sub_desciption_2_4 = [
    "Dermal filler results are typically visible immediately, although the final outcome may take a few weeks to fully show.",
    "Right after your treatment, you may experience tenderness, redness, swelling, and possibly mild bruising. These symptoms usually subside within a few days.",
    "For the first 24 hours following your treatment, please avoid any actions that might irritate the injection sites, such as rubbing, touching, or applying pressure. This helps prevent filler from spreading away from the intended areas.",
    "It's advisable to refrain from intense exercise for the first 24 hours after your treatment.",
    "You can apply ice packs to areas with swelling for up to 3 days following the treatment.",
    "The development of lumps or bumps is a normal occurrence after filler treatment and may take a few weeks to settle. Your doctor may schedule a follow-up appointment 2 weeks after your treatment, during which they may perform a tissue massage or a 'top-up' if necessary.",
    "If you experience increasing pain or notice skin changes developing, please contact us immediately.",
    "If you have any concerns or questions, please don't hesitate to reach out. We are always available to assist you!"
];

const sub_desciption_map_2 = [
    sub_desciption_2_1,
    sub_desciption_2_2,
    sub_desciption_2_3,
    sub_desciption_2_4
];

// treatment 3
const treatment_name_3 = "Restylane Skinboosters";
const treatment_description_3 = "Restyalne Skinboosters are a unique treatment designed to improve skin quality by boosting hydration levels deep within the skin. This procedure involves microinjections of a soft, gel-like substance (hyaluronic acid) into the skin.The result is increased hydration, improved skin elasticity, and a smoother texture. Common treatment areas include the face, neck, décolletage, and hands.Restylane Skinboosters are suitable for all skin types and are particularly beneficial for those looking to enhance their skin’s overall radiance and health. This treatment is minimally invasive and provides a subtle, yet noticeable, improvement in skin quality.";

const sub_desciption_3_1 = [
    "Restylane Skinboosters utilize stabilized hyaluronic acid, a naturally occurring substance in the body that helps to maintain skin hydration and elasticity. The microinjections, delivered using a needle or cannula, administer hyaluronic acid directly into the skin, where it works to attract and retain moisture, thus improving the skin’s hydration levels from within.",
    "Over time, this increased hydration helps to improve skin texture, firmness, and elasticity, resulting in a more youthful and radiant appearance."
];

const sub_desciption_3_2 = [
    "As we age, our skin’s natural hyaluronic acid levels decrease, leading to dryness, loss of elasticity, and the formation of fine lines and wrinkles. Restylane Skinboosters can help to counteract these effects by replenishing the skin’s hyaluronic acid levels and providing deep hydration. This treatment is ideal for anyone looking to improve their skin’s overall quality, whether you’re dealing with dryness, rough texture, or just want a radiant glow. Restylane Skinboosters are also effective for treating acne scars and improving the skin’s overall tone and texture."
];

const sub_desciption_3_3 = [
   "Ensure your skin is clean and free of makeup before your appointment.",
   "Avoid consuming alcohol for 24 hours prior to your treatment to reduce the risk of bruising.",
   "Refrain from taking any blood-thinning medications, such as aspirin or anti-inflammatories, for one week before your treatment unless prescribed by your doctor.",
   "If you exercise regularly, it’s recommended to do so before your appointment."
];

const sub_desciption_3_4 = [
    "You may experience some redness, swelling, or tenderness at the injection sites immediately following the treatment. These side effects typically resolve within a few days.",
    "Avoid touching, rubbing, or applying pressure to the treated areas for the first 24 hours to prevent the risk of infection and allow the product to settle.",
    "Stay well-hydrated and avoid excessive sun exposure for a few days following the treatment.",
    "You can apply ice packs to any swollen areas to help reduce swelling.",
    "Results from Restylane Skinboosters typically start to appear within a few days and can last up to six months, depending on the individual and the treatment area.",
    "If you have any questions or concerns, please don’t hesitate to contact us. We are here to ensure you have the best possible experience."
];


const sub_desciption_map_3 = [
    sub_desciption_3_1,
    sub_desciption_3_2,
    sub_desciption_3_3,
    sub_desciption_3_4
];

// treatment 4
const treatment_name_4 = "Sclerotherapy";
const treatment_description_4 = "Sclerotherapy is a minimally invasive treatment for telangiectasia (spider veins) and small to medium-sized varicose veins. It involves injecting unwanted superficial veins directly with a detergent-like chemical using a tiny needle. This causes the veins to spasm and gradually be broken down and absorbed by the body over time. Typically, 2-4 sessions, each lasting about 60 minutes, are required for complete vein treatment, with a minimum of six weeks between sessions. This treatment is purely cosmetic and is generally not very painful.";

const sub_desciption_4_1 = [
    "Sclerotherapy works by stimulating the body’s natural healing process. When veins are injected with a detergent-like solution, it causes damage to the inner layer of the vein known as the endothelium. This causes the injected vein to spasm, adhere together, and scar through a process known as fibrosis. As a result, the treated veins dry out and shrink, and are gradually absorbed by the body, making them no longer visible on the skin’s surface",
    "At Vita, we exclusively use polidocanol as our agent of choice as it carries the safest side effect profile and is the least painful agent."
];

const sub_desciption_4_2 = [
    "Varicose veins and spider veins can be a significant source of distress for many individuals. Not only can these veins look aesthetically unpleasing, but they can greatly impact one’s confidence and self-esteem. Fortunately, they can be treated. Sclerotherapy provides a means to treat these unwanted veins with minimal down time and pain.",
    "It is essential to note that sclerotherapy is primarily a cosmetic treatment. If your veins are notably large or symptomatic, your doctor may recommend consulting a vascular surgeon for relevant testing.",
    "Importantly, sclerotherapy should not be performed on patients displaying signs of venous thrombosis (e.g. deep vein thrombosis or DVT), pregnant women, diabetics with target organ damage, or patients with evidence of peripheral arterial disease."
];

const sub_desciption_4_3 = [
    "If you have exercise daily, it's advisable to do so before your appointment.",
    "Please refrain from consuming alcohol within 24 hours prior to your treatment.",
    "Note that blood-thinning medications can increase the risk of bleeding and bruising. To mitigate this, we recommend avoiding all over-the-counter blood-thinning medications, such as anti-inflammatories or aspirin, for one week before your treatment, unless they are part of your regular prescription from your doctor.",
    "On the day of your treatment, avoid using any topicals that may make blood vessels more difficult to see, such as self-tanning products or moisturizers."
];

const sub_desciption_4_4 = [
    "Immediately after your treatment you may experience some tenderness, redness, itching, and/or swelling. These symptoms typically resolve within a few days to a week.",
    "Following treatment, your doctor will apply compression bandages to your legs which must be worn continuously for 48 hours when only spider veins are treated, and for 7 days when varicose veins are treated. These bandages serve to minimize inflammation, reduce blood clot formation, maintain vein wall contact, and minimize complications.",
    "It’s recommended to walk for 20 minutes a day for the next 2 weeks.",
    "Avoid hot baths or saunas for 1-2 weeks.",
    "Refrain from intense exercise, such as running, cycling, or leg resistance training, for 2 weeks.",
    "Protect the treated areas from sun exposure for 2 weeks. Alternatively, apply sunscreen with a high SPF (>30).",
    "If you are experiencing increasing pain or notice any ulceration developing at the injection site (please note, this is a very rare side effect when using polidocanol), please contact us immediately.",
    "If you have any concerns or questions, please don't hesitate to reach out. We are always here to assist you!"
];

const sub_desciption_map_4 = [
    sub_desciption_4_1,
    sub_desciption_4_2,
    sub_desciption_4_3,
    sub_desciption_4_4
];

// treatment 5
const treatment_name_5 = "Sculptra";
const treatment_description_5 = "Sculptra is an injectable treatment designed to stimulate collagen production and restore volume to areas of the face that have lost volume due to aging. Unlike traditional fillers, Sculptra works gradually over time to rebuild the skin's underlying structure, leading to a more natural and youthful appearance. Common treatment areas include the cheeks, temples, and nasolabial folds (smile lines). Sculptra is particularly beneficial for individuals looking for a long-lasting solution to facial volume loss and skin laxity.";

const sub_desciption_5_1 = [
    "Sculptra contains poly-L-lactic acid, a biocompatible synthetic substance that stimulates the body’s own collagen production. When injected into the deeper layers of the skin, Sculptra gradually promotes the growth of new collagen fibres, which helps to restore facial volume and improve skin texture. The results develop over a series of treatments and can last for up to two years, providing a subtle, natural-looking improvement."
];

const sub_desciption_5_2 = [
    "As we age, our body’s collagen production decreases, leading to volume loss, sagging skin, and the formation of wrinkles. Sculptra is ideal for individuals who want a gradual, long-lasting solution to these common signs of aging. This treatment is perfect for those seeking a natural look, as the results appear gradually over time. Sculptra can help restore facial contours, reduce the appearance of wrinkles, and improve overall skin quality."
];

const sub_desciption_5_3 = [
    "Ensure your skin is clean and free of makeup before your appointment.",
    "Avoid consuming alcohol for 24 hours prior to your treatment to reduce the risk of bruising.",
    "Refrain from taking any blood-thinning medications, such as aspirin or anti-inflammatories, for one week before your treatment unless prescribed by your doctor.",
    "If you exercise regularly, it’s recommended to do so before your appointment."
];

const sub_desciption_5_4 = [
    "You may experience some redness, swelling, or tenderness at the injection sites immediately following the treatment. These side effects typically resolve within a few days.",
    "Avoid touching, rubbing, or applying pressure to the treated areas for the first 24 hours to prevent the risk of infection and allow the product to settle.",
    "Stay well-hydrated and avoid excessive sun exposure for a few days following the treatment.",
    "Massage the treated areas as instructed by your provider to ensure even distribution of the product.",
    "Results from Sculptra develop gradually over a few months and can last up to two years, depending on the individual and the treatment area.",
    "If you have any questions or concerns, please don’t hesitate to contact us. We are here to ensure you have the best possible experience."
];

const sub_desciption_map_5 = [
    sub_desciption_5_1,
    sub_desciption_5_2,
    sub_desciption_5_3,
    sub_desciption_5_4
];

export default Treatment;