import React, { useState } from 'react';
import logo from '../assets/logos/vita_logo_white.png';
import { db, collection, addDoc } from '../firebase';

const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (e) => {
      e.preventDefault();
      
      const ref = collection( db ,'contacts');

      addDoc(ref, {
        name:name,
        email:email,
        message:message,
        createdAt: Date.now()
      })
      .then(() => {
        alert("Your message has been submitted. Thank you");
      })
      // .catch(error => {
      //   alert("There was an error, please try again.");
      // });

      setName("");
      setEmail("");
      setMessage("");
    
    };

    return (
        <div className="min-h-screen flex items-center justify-center py-12 px-4 pt-24 lg:pt-0 sm:px-6 lg:px-8 bg-white">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-16 md:h-24 w-auto rounded-full"
              src={logo}
              alt=""
            />
             <h2 className="text-center text-vitagrey text-3xl font-vitaHeadings tracking-wide my-6" >Contact Us</h2>
          </div>
          <form className="mt-8 space-y-6 font-vitaBody text-vitagrey" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
            <div>
                <label htmlFor="email-address" className="sr-only">
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  autoComplete="name"
                  required
                  className="appearance-none font-extrabold md:font-semibold rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-sm focus:outline-none focus:ring-vitagold focus:border-vitagold focus:z-10 text-sm"
                  placeholder="Name"
                  value={name}
                  onChange={ (e) => setName(e.target.value) }
                />
              </div>
              <div className="font-vitaBody text-vitagrey">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none font-extrabold md:font-semibold rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-vitagold focus:border-vitagold focus:z-10 text-sm"
                  placeholder="Email address"
                  value={email}
                  onChange={ (e) => setEmail(e.target.value) }
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  type="textarea"
                  required
                  className="appearance-none font-extrabold md:font-semibold relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-sm focus:outline-none focus:ring-vitagold focus:border-vitagold focus:z-10 text-sm"
                  placeholder="Leave your message here."
                  value={message}
                  onChange={ (e) => setMessage(e.target.value) }
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="group font-extrabold md:font-semibold relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-vitagold hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-viatgold"
              >
                Leave Message
              </button>
            </div>
          </form>
          <div className="mt-4 space-y-4 font-vitaBody text-vitagrey">
            <h4 className="text-center text-vitagrey text-lg font-vitaBody font-extrabold tracking-wide my-2">OR</h4>
          </div>
          <div className="mt-4 space-y-2 font-vitaBody text-vitagrey">
            <h3 className="text-center text-vitagrey text-xl lg:text-2xl font-vitaHeadings tracking-wide my-4">Book by contacting us directly on:</h3>
            <p className="text-center text-vitagrey text-sm font-vitaBody font-extrabold tracking-wide my-2">Whatsapp: +27 71 655 4299</p>
            <p className="text-center text-vitagrey text-sm font-vitaBody font-extrabold tracking-wide my-2">Email: hello@vitaaesthetics.co.za</p>
          </div>


        </div>
      </div>
    );
}

export default Contact;