import React from 'react';
import {Twitter, Facebook, Instagram} from 'react-bootstrap-icons'
import logo from '../assets/logos/vita_logo_white.png';
import Navlist from './Subcomponents/NavlistDark';

const Footer = () => {
    const currentdate = new Date();
    return (
        <div className="relative mt-auto flex items-center justify-center flex-col border-t-2 border-vitagold shadow-lg bottom-0 h-40 md:h-56 w-screen bg-vitagrey p-10 z-10">
          <Navlist />
          <a href="/"><img className="h-16 my-2" src={logo} /></a>
           <Social />
           <p className="my-2 text-white text-xs tracking-wide font-vitaBody">Vita Aesthetics | &#169; {currentdate.getFullYear()}</p>
        </div>
    );
}

const Social = () => {
    return (
        <div className="flex items-center justify-center flex-row">
            {/* <Icon icon={<Twitter size="20" />} /> */}
            <Icon icon={<Facebook size="20" />} link="https://www.facebook.com/vitaaestheticscpt" />
            <Icon icon={<Instagram size="20" />} link="https://www.instagram.com/vitaaestheticscpt/" />
        </div>
    );
}

const Icon = ({ icon, link }) => {
    return (
        <a target="_blank" className='items-center mx-2 my-2' href={ link }><i className="text-white hover:text-vitagold transition-all duration-100"> { icon } </i> </a>
    );
}

export default Footer;